export const ChatConfig = {
	SCROLL_THROTTLE_MS: 150,
	AUTO_SCROLL_TO_BOTTOM_TRIGGER_PX: 40,
	// (0, 1] value representing scroll progress remaining until ChatList should fetch next messages
	LOAD_NEXT_MESSAGES_TRIGGER_PERCENT: 0.3,
	TYPING_STATUS_THROTTLE_MS: 2500,
	TYPING_STATUS_DISPLAY_MS: 15000,
};

export const ChatBot = {
	MSG_CONTENT_INTRO: '👋 Hey! Welcome to Asaph, the worship team platform.',
	MSG_CONTENT_MEMBER_ONBOARD:
		'Help your team grow together and work better. Invite them now to get started! 👇\n',
	MSG_CONTENT_INVITED: (name: string) =>
		`Invite sent to ${name}! You can chat with people when they join your team.`,
	MSG_CONTENT_INVITED_CALL_TO_CHAT:
		'Until then, feel free to add more team members anytime! 👌',
};

export const Text = {
	UNKNOWN_ERROR_MESSAGE: 'Unknown error occured, please refresh the page',
};

export const Video = {
	STATS_UPDATE_THROTTLE_MS: 5000,
};

export const Thumbnail = {
	RESOLUTION_HIGH: { width: 1233, height: 1920 },
	RESOLUTION_LOW: { width: 514, height: 800 },
};

export const appTitle = 'Asaph';

export const Pricing = {
	MONTHLY_AUD: 28,
	MONTHLY_USD: 24,
	YEARLY_AUD: 252,
	YEARLY_USD: 216,
	MONTHLY: 'Monthly',
	YEARLY: 'Yearly',
	AUD: 'AUD',
	USD: 'USD',
};

export const SupportPricingTier1 = {
	YEARLY: 60,
	PRICE_PLAN_ID_PREFIX: 'Supporter-Support',
	AUD: 'AUD',
	USD: 'USD',
};

export const SupportPricingTier2 = {
	YEARLY: 240,
	PRICE_PLAN_ID_PREFIX: 'Supporter-Grow',
	AUD: 'AUD',
	USD: 'USD',
};

export const SupportPricingTier3 = {
	YEARLY: 480,
	PRICE_PLAN_ID_PREFIX: 'Supporter-Scale',
	AUD: 'AUD',
	USD: 'USD',
};

export const unknownDisplayName = 'Unknown';
export const unknownEmail = 'anonymized';

export const sendingMessageStatus = 'sending';

export const DASHBOARD_MAX_COLLECTIONS = 3;

export const PANEL_TRANSITION_TIMEOUT = 300;

export const INITIAL_MESSAGES_SIZE = 20;

// this controlls the number of songs in the "Add song" modal
export const INITIAL_SONGLIST_RESULT_SIZE = 20;

export enum LinkFrom {
	dashboard = 'dashboard',
	messages = 'messages',
	library = 'library',
}

export const termsOfServiceLink = 'https://asaph.io/terms-of-service';
export const privacyPolicyLink = 'https://asaph.io/privacy-policy';

export const DefaultKeys = [
	'C',
	'Db',
	'D',
	'Eb',
	'E',
	'F',
	'F#',
	'G',
	'Ab',
	'A',
	'Bb',
	'B',
];

export const DAYS_OF_WEEK = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

export const REMOVED_SONG_NAME = 'Removed song';

export type RotationStatusWarningValidateFn = (
	datesLastPlayed: string[], // List of relevant ISO dates when the song was played
	comparisonISODate: string, // ISO date used for the comparison logic
) => boolean;

export const ROTATION_STATUSES: {
	key: string;
	description: string;
	warningDescription?: string;
	capitalisedLabel: string;
}[] = [
	{
		key: 'high',
		description: '2 or more times per month',
		warningDescription: 'it has been played more than 2 times ',
		capitalisedLabel: 'High',
	},
	{
		key: 'regular',
		description: 'Once per month',
		capitalisedLabel: 'Regular',
		warningDescription:
			'This song is on regular rotation, but has already been played or scheduled in the last month.', // <warning> It was last played <date>
	},
	{
		key: 'low',
		description: 'Once every few months',
		capitalisedLabel: 'Low',
		warningDescription:
			'This song is on low rotation, but has already been played or scheduled in the last few months.', // <warning> It was last played <date>
	},
	{
		key: 'occasional',
		description: '1-2 times per year',
		capitalisedLabel: 'Occasional',
		warningDescription:
			'This song is on occasional rotation, but has already been played or scheduled twice in the last year.', // <warning> It was last played <date>
	},
	{
		key: 'seasonal',
		description: 'Best for items, Easter and Christmas',
		capitalisedLabel: 'Seasonal',
	},
	{
		key: 'rest',
		description: 'Out of rotation',
		capitalisedLabel: 'Rest',
		warningDescription: 'This song is currently out of rotation.', // <warning> It was last played <date>
	},
];

export const ENERGY_TAGS = [
	{ label: 'upbeat', capitalisedLabel: 'Upbeat' },
	{ label: 'building', capitalisedLabel: 'Building' },
	{ label: 'settling', capitalisedLabel: 'Settling' },
	{ label: 'reflective', capitalisedLabel: 'Reflective' },
];

export const EMOTION_TAGS = [
	{ label: 'fear' },
	{ label: 'anger' },
	{ label: 'worry' },
	{ label: 'sadness' },
	{ label: 'peace' },
	{ label: 'contentment' },
	{ label: 'joy' },
	{ label: 'love' },
];

export const GOSPEL_TAGS = [
	{ label: 'glory of god', capitalisedLabel: 'Glory of God' },
	{ label: 'gravity of sin', capitalisedLabel: 'Gravity of Sin' },
	{ label: 'grandeur of grace', capitalisedLabel: 'Grandeur of Grace' },
];

export const GOSPEL_TAGS_SHORT = [
	{ label: 'god', capitalisedLabel: 'God' },
	{ label: 'sin', capitalisedLabel: 'Sin' },
	{ label: 'grace', capitalisedLabel: 'Grace' },
];

export const SONG_TYPE_TAGS = [
	{ label: 'chorus', capitalisedLabel: 'Chorus' },
	{ label: 'hymn', capitalisedLabel: 'Hymn' },
];

export const PERSONAL_STEP_LABEL_MAP = [
	'Not at all',
	'Meh',
	'Good',
	'Great',
	'Amazing',
];
export const TEAM_STEP_LABEL_MAP = [
	'Unprepared',
	'Meh',
	'Good',
	'Great',
	'Crushed it',
];
export const CONGREGATIONAL_STEP_LABEL_MAP = [
	'Struggling',
	'Flat',
	'Good',
	'Great',
	'Belting it',
];

export const ASAPH_BOT_USER_ID = '0';
export const ASAPH_BOT_DISPLAY_NAME = 'Asaph';
export const REMINDERS_LOCAL_STORAGE_KEY = 'asahph-reminders';
export const ENV_OVERRIDE_STORAGE_KEY = 'asahph-env-override';
export const DRAFT_SETLISTS_STORAGE_KEY = 'asahph-draft-setlists';
