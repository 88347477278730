import React from 'react';

import { ROTATION_STATUSES } from '@/config/constants';
import {
	AnalysisOutputItem,
	useListSongsQuery,
	useReanalyzeSongMutation,
	useUpdateSongMutation,
} from '@/graphql';
import {
	AspectRatio,
	Box,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardProps,
	Center,
	Divider,
	Flex,
	Image,
	Select,
	Tag,
	Text,
	Wrap,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

type SongRowProps = CardProps & {
	id: string;
	name: string;
	artworkUrl?: string | null;
	artist: string;
	footer?: React.ReactNode;
	songThemes?: {
		emotionTags: string[];
		energyTags: string[];
		gospelTags: string[];
		songTypeTags: string[];
	};
	overlay?: React.ReactNode;
	overlayColor?: string;
	variations?: string;
	analysisOutput?: AnalysisOutputItem[] | null;
	rotationStatus?: string | null;
};

const SongRow: React.FC<SongRowProps> = ({
	id,
	name,
	artworkUrl,
	artist,
	footer,
	songThemes,
	overlay,
	overlayColor,
	variations,
	analysisOutput,
	rotationStatus,
	...props
}) => {
	const queryClient = useQueryClient();
	const hasVariations = variations && variations.length > 2;
	const hasAnalysisOutput = analysisOutput && analysisOutput.length;

	const { refetch, isLoading, isRefetching } = useListSongsQuery();

	const {
		mutateAsync: analyzeMutateAsync,
		error: analyzeSongError,
		isLoading: analyzeSongLoading,
	} = useReanalyzeSongMutation({
		onSuccess: (data) => {
			// Optimistically update to the new value
			queryClient.setQueryData(['ListSongs'], (old) => {
				console.log(data);
				console.log(old);
				return old;
			});
		},
	});

	const {
		mutateAsync: updateMutateAsync,
		error: updateSongError,
		isLoading: updateSongLoading,
	} = useUpdateSongMutation({
		onSuccess: () => refetch(),
	});

	return (
		<Flex
			width="100%"
			gap={2}
			{...props}
			display="inline-flex"
			alignItems="center"
			border="1px solid #e2e8f0"
			p={2}
		>
			<Box position="relative" flexShrink={0}>
				<Image
					width="40px"
					height="40px"
					src={artworkUrl ?? ''}
					fallbackSrc="/album.png"
				/>
				{overlay ? (
					<AspectRatio
						marginTop="-100%"
						opacity={0.7}
						w="100%"
						background={overlayColor ? overlayColor : 'blue.200'}
						ratio={1}
					>
						<Center>
							<Text
								fontSize="30px"
								color="white"
								fontWeight="bold"
							>
								{overlay}
							</Text>
						</Center>
					</AspectRatio>
				) : null}
			</Box>
			<Box flex={1} minWidth="250px">
				<Text mt="10px" as="b">
					{name}
				</Text>
				<Text>{artist}</Text>
			</Box>
			{songThemes && (
				<Wrap mt={2}>
					{[
						...songThemes.energyTags,
						...songThemes.emotionTags,
						...songThemes.gospelTags,
						...songThemes.songTypeTags,
					].map((t) => (
						<Tag size="sm">{t}</Tag>
					))}
				</Wrap>
			)}
			<Button
				size="sm"
				width="90px"
				flexShrink={0}
				isDisabled
				colorScheme={hasVariations ? 'green' : 'gray'}
				variant={hasVariations ? 'solid' : 'outline'}
			>
				Variations
			</Button>
			<Button
				size="sm"
				width="90px"
				flexShrink={0}
				colorScheme={hasAnalysisOutput ? 'green' : 'gray'}
				variant={hasAnalysisOutput ? 'solid' : 'outline'}
				isLoading={analyzeSongLoading}
				onClick={() => {
					analyzeMutateAsync({ songId: id });
				}}
			>
				Analysis
			</Button>
			<Select
				width="120px"
				flexShrink={0}
				size="sm"
				name="rotationStatus"
				onChange={(e) => {
					updateMutateAsync({
						songId: id,
						input: {
							artist,
							name,
							rotationStatus: e.currentTarget.value,
						},
					});
					console.log(e.currentTarget.value);
				}}
				placeholder="None"
				isDisabled={isLoading || isRefetching}
			>
				{ROTATION_STATUSES.map(({ key }) => (
					<option selected={rotationStatus === key} value={key}>
						{key}
					</option>
				))}
			</Select>
			{footer}
		</Flex>
	);
};

export default SongRow;
