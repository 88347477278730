import React, { useState } from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import {
	GetAllWeightedTeamSongsMutation,
	ListTeamSongsQuery,
	useGetAllWeightedTeamSongsMutation,
	useListTeamsQuery,
} from '@/graphql';
import { GenerateSetlistRequestInputSchema } from '@/graphql/validation';
import {
	Box,
	Button,
	Center,
	Select,
	Spinner,
	Table,
	TableCaption,
	TableContainer,
	Tag,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useToast,
	Wrap,
} from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import Editor from 'react-simple-code-editor';
import { useLocalStorage } from 'react-use';

export const LOCAL_STORAGE_ITEM_KEY = 'song-weight-test-item';

const generateSetlistRequestInput = GenerateSetlistRequestInputSchema();

type WeightedTeamSong =
	GetAllWeightedTeamSongsMutation['getAllWeightedTeamSongs'][0];

const AiSettings = () => {
	const [teamId, setTeamId] = useState('');
	const [requestSaved, setRequestSaved] = useLocalStorage<string>(
		'explore-weighted-songs-request',
		'',
	);
	const [request, setRequest] = useState<string>(
		requestSaved ||
			JSON.stringify(
				{
					energyTags: [],
				},
				null,
				4,
			),
	);
	const [requestInputError, setRequestInputError] = useState('');

	const {
		data: teamsQuery,
		isLoading: isTeamsLoading,
		error: teamsError,
	} = useListTeamsQuery();

	const {
		mutateAsync: mutateAsync,
		data: weightedTeamSongsMutation,
		isLoading: isWeightedTeamSongsLoading,
		error: weightedTeamSongsError,
	} = useGetAllWeightedTeamSongsMutation();

	const tableData = weightedTeamSongsMutation?.getAllWeightedTeamSongs || [];

	return (
		<Box>
			<Select
				mb={2}
				disabled={isTeamsLoading}
				onChange={(e) => setTeamId(e.currentTarget.value)}
				placeholder="Select team"
			>
				{teamsQuery?.listTeams.map((t) => (
					<option value={t.id}>{t.name}</option>
				))}
			</Select>

			<Editor
				value={request}
				onValueChange={(code) => {
					setRequest(code);
					setRequestSaved(code);
				}}
				highlight={(code) => highlight(code, languages.js, 'json')}
				padding={10}
				style={{
					fontFamily: '"Fira code", "Fira Mono", monospace',
					fontSize: 12,
					border: '1px solid gray',
				}}
			/>

			<Button
				onClick={async () => {
					try {
						setRequestInputError('');
						const requestParsed = JSON.parse(request);
						const res = await generateSetlistRequestInput.validate(
							requestParsed,
						);
						await mutateAsync({
							request: res,
							teamId: teamId,
						});
					} catch (err) {
						if (err instanceof Error) {
							return setRequestInputError(err.toString());
						}
						console.log(err);
						setRequestInputError('There was an error');
					}
				}}
				mt={2}
				isDisabled={!request || !teamId}
			>
				Generate Setlist
			</Button>

			{requestInputError || teamsError || weightedTeamSongsError ? (
				<div>
					<div>There was an error</div>
					<ErrorInspector
						JSON={{
							requestInputError,
							teamsError,
							weightedTeamSongsError,
						}}
					/>
				</div>
			) : null}

			{isWeightedTeamSongsLoading && (
				<Center>
					<Spinner />
				</Center>
			)}
			{!!tableData.length && (
				<Box mt={5}>
					<WeightedTeamSongsTable data={tableData} />
				</Box>
			)}
		</Box>
	);
};

export default AiSettings;

const columnHelper = createColumnHelper<WeightedTeamSong>();

const SongTags = ({ tags }: { tags?: string[] }) => {
	const safeTags = tags || [];
	return (
		<Wrap>
			{safeTags.map((t) => (
				<Tag>{t}</Tag>
			))}
		</Wrap>
	);
};

const WeightedTeamSongsTable: React.FC<{ data: WeightedTeamSong[] }> = ({
	data,
}) => {
	const [, setTestItem] = useLocalStorage<null | string>(
		LOCAL_STORAGE_ITEM_KEY,
		null,
	);
	const toast = useToast();
	const columns = [
		columnHelper.accessor('teamSong.name', {
			id: 'name',
			cell: (info) => info.getValue(),
			header: () => <span>Song Name</span>,
		}),
		columnHelper.accessor((row) => row.teamSong.artist, {
			id: 'artist',
			cell: (info) => <i>{info.getValue()}</i>,
			header: () => <span>Artist</span>,
		}),
		columnHelper.accessor('teamSong.energyTags', {
			cell: (info) => <SongTags tags={info.getValue()} />,
			header: () => <span>Energy Tags</span>,
		}),
		columnHelper.accessor('teamSong.emotionTags', {
			cell: (info) => <SongTags tags={info.getValue()} />,
			header: () => <span>Emotion Tags</span>,
		}),
		columnHelper.accessor('teamSong.gospelTags', {
			cell: (info) => <SongTags tags={info.getValue()} />,
			header: () => <span>Gospel Tags</span>,
		}),
		columnHelper.accessor('teamSong.songTypeTags', {
			cell: (info) => <SongTags tags={info.getValue()} />,
			header: () => <span>Song Type Tags</span>,
		}),
		columnHelper.accessor('teamSong.rotationStatus', {
			header: () => 'Rotation Status',
			cell: (info) => info.renderValue(),
		}),
		columnHelper.accessor('teamSong.analysisOutput', {
			header: () => 'Analysis Output',
			cell: (info) => JSON.stringify(info.getValue() || {}, null, 2),
		}),
		columnHelper.accessor('weight', {
			header: () => 'Total Weight',
			cell: (info) => info.renderValue(),
		}),
		columnHelper.accessor('errors', {
			header: () => 'Errors',
			cell: (info) => info.getValue().join(', '),
		}),
		columnHelper.display({
			id: 'edit',
			cell: (info) => (
				<Button
					variant="ghost"
					size="xs"
					onClick={() => {
						setTestItem(JSON.stringify(info.row.original.teamSong));
						toast({
							description:
								'Test item set, you can now go to /ai/song-weights/:id and run a test',
						});
					}}
				>
					Use in tests
				</Button>
			),
		}),
		// setTestItem
	];
	const table = useReactTable({
		columns,
		data,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<TableContainer>
			<Table variant="simple">
				<TableCaption>
					List of all TeamSongs with their combined weight
				</TableCaption>
				<Thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<Tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<Th key={header.id}>
									{header.isPlaceholder
										? null
										: flexRender(
												header.column.columnDef.header,
												header.getContext(),
										  )}
								</Th>
							))}
						</Tr>
					))}
				</Thead>
				<Tbody>
					{table.getRowModel().rows.map((row) => (
						<Tr key={row.id}>
							{row.getVisibleCells().map((cell) => (
								<Td key={cell.id}>
									{flexRender(
										cell.column.columnDef.cell,
										cell.getContext(),
									)}
								</Td>
							))}
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
